<ng-container>
  <h2 class="section-title">Public API Catalog</h2>
  <div class="table-header-row">
    <div></div>
    <div>
      <wg-search
      [placeholder]="'Search'"
      [initObjects]="catalogs"
      [filterKeys]="filterKeys"
      [searchTerm]="searchTerm"
      (filterObjects)="filtered($event)"></wg-search>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let category of categories">
      <div class="col-sm-3 d-flex pb-3">
        <div class="card flex-fill">
          <div
            class="card-img-top background-tarmac"
            style="cursor: pointer;"
            [routerLink]="['/catalogs', category.id]">
            <wg-icon
              class="size-48 color-snow"
              name="{{category.ThumbnailIcon}}">
            </wg-icon>
            <h2 class="color-snow">{{ category.name }}</h2>
            <p class="card-text color-snow">{{ category.description }}</p>
          </div>
          <div class="card-body">
            <ul><li *ngFor="let subCat of category.subcategories">
              <a [routerLink]="['/catalogs', category.id, subCat.id ]">{{ subCat.name }}</a></li>
            </ul>
          </div>
          <div class="card-footer">
              <small class="text-muted"><a href={{category.HelpOrReferenceUrl}} target="_blank">Read more...</a></small>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<wg-notifications></wg-notifications>
<wg-loader
  *ngIf="fetchingData"
   [scale]="2"
   [modal]="true"
   [color]="'#666666'">
</wg-loader>
