import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { HeaderMainComponent } from './header-main/header-main.component';


@NgModule({
  declarations: [
    HeaderMainComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    HeaderMainComponent
  ]
})
export class HeaderModule { }
