import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { FooterMainComponent } from './footer-main/footer-main.component';


@NgModule({
  declarations: [
    FooterMainComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FooterMainComponent
  ]
})
export class FooterModule { }
