import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';

import { Subscription } from 'rxjs';

/**
 * WG imports
 */
import { WgNotificationsService } from '@watchguard/wg-notifications';
import { CatalogApiService } from '../../core/shared-services/catalog-api.service';

@Component({
  selector: 'wgc-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent implements OnInit, OnDestroy {
  fetchingData: boolean;
  catalogs: any[];
  catalogs$: Subscription;
  searchTerm = '';
  categories: any[];
  filterKeys = ['id', 'name', 'description', 'subcategories'];

  constructor(
    private catalogService: CatalogApiService,
    private notificationService: WgNotificationsService) { }

  ngOnDestroy() {
    if (this.catalogs$) {
      this.catalogs$.unsubscribe();
    }
  }

  ngOnInit() {
    this.fetchingData = true;
    this.catalogService.getCatalogs().subscribe({
      next: (data: any) => {
        if (data) {
          this.fetchingData = false;
          this.catalogs = data.categories;
          this.categories = this.catalogs;
        }
      },
      error: () => {
        this.showPageErrorNotification();
      }
    });    
  }

  filtered(objs): void {
    this.categories = JSON.parse(JSON.stringify(objs));
  }

  showPageErrorNotification(): void {
    this.notificationService.error('Could not load API Catalog, Please try again after some time.');
  }
}
