import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

/**
 * WG imports
 */
import { WgNotificationsService } from '@watchguard/wg-notifications';
import { CatalogApiService } from 'src/app/core/shared-services/catalog-api.service';

@Component({
  selector: 'wgc-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.scss']
})
export class SubCategoryListComponent implements OnInit {
  fetchingData: boolean;
  apis: any[] = [];
  subCategories: any[] = [];
  subCategoriesInitObject: any[] = [];
  categoryId: string;
  categoryName: string;
  subCategoryId: string;
  subCategoryNames: any[] = [];
  subCategorySelected: any;
  initObjects: any[] = [];
  searchTerm = '';
  categories: any[];
  filterKeys = ['id', 'name', 'description', 'version', 'title'];

  constructor(
    private catalogService: CatalogApiService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: WgNotificationsService
    ) { }

  ngOnInit() {
    this.categoryId = this.route.snapshot.params['id'];
    this.subCategoryId = this.route.snapshot.params['subcatid'];
    this.subCategoryNames.push({
      id: this.categoryId,
      val: 'All'
    });
    this.getSubCategoryData();
  }

  getSubCategoryData(): void {
    this.fetchingData = true;
    this.catalogService.getCatalogs().pipe(
      finalize(() => this.fetchingData = false))
      .subscribe({
      next: (data: any) => {
        if (data) {
          const foundCatalog = data?.categories.find(catalog => catalog.id === this.categoryId);

          if (foundCatalog) {
            this.categoryName = foundCatalog.name;
            let index = 0;
            for (let subCat of foundCatalog.subcategories) {
              index = index + 1;
              this.initObjects.push(subCat);
              this.subCategoryNames.push({ id: subCat.id, val: subCat.name });
              if (subCat.id === this.subCategoryId) {
                this.subCategorySelected = this.subCategoryNames[index];
                this.setSubCategories(subCat);
              } else if (!this.subCategoryId) {
                this.setSubCategories(subCat);
              }
            }
          }
          this.handleCategoryErrors();
        }
      },
      error: () => {
        this.router.navigate(['catalogs']);
      }
    });       
  }

  handleCategoryErrors(): void {
    if (!this.categoryName) {
      this.router.navigate(['catalogs']);
    } else if (this.subCategories.length === 0) {
      this.showPageNotification('error');
    }
  
    if (!this.subCategorySelected) {
      this.subCategorySelected = this.subCategoryNames[0];
    }
  
    this.subCategoriesInitObject = this.subCategories;
  }

  getApiData(subCategoryId: string): void {
    this.fetchingData = true;
    this.catalogService.getApis(this.categoryId, subCategoryId)?.subscribe({
      next: (data: any) => {
        this.fetchingData = false;
        if (data) {
          this.apis.push(this.catalogService.getApiList(data));
        }
      },
      error: () => {
        this.router.navigate(['catalogs']);
      }
    });    
  }

  filteredSubCategories(objs): void {
    this.subCategories = JSON.parse(JSON.stringify(objs));
    this.apis = [];
    for (let subCat of this.subCategories) {
      this.getApiData(subCat.id);
    }
    this.notificationService.clear();
    if (this.subCategories.length === 0) {
      this.showPageNotification('info');
    }
  }

  onSubCategorySelected(event: any): void {
    this.subCategories = [];
    this.apis = [];
    for (let subCat of this.initObjects) {
      if (event.id === this.categoryId) {
        this.setSubCategories(subCat);
      } else if (event.id === subCat.id ) {
        this.setSubCategories(subCat);
        this.router.navigate(['catalogs', this.categoryId, event.id]);
        break;
      }
    }
    if (event.id === this.categoryId) {
      this.router.navigate(['catalogs', this.categoryId]);
    }
    this.subCategoriesInitObject = this.subCategories;
  }

  private showPageNotification(type: string): void {
    if (type === 'error') {
      this.notificationService.error('This Sub-Category does not exist!!!!');
    } else {
      this.notificationService.info('No Results for this search!');
    }
  }

  setSubCategories(subCat: any) {
    this.subCategories.push(subCat);
    this.getApiData(subCat.id);
  }

}
