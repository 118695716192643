import { NgModule } from '@angular/core';

import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  imports: [
  ],
  declarations: [
    PageNotFoundComponent
  ],
  exports: [
    PageNotFoundComponent
  ]
})
export class PageNotFoundModule { }
