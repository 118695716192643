import { NgModule } from '@angular/core';

/**
 * WG imports
 */
import { AppComponent } from './app.component';
import { ApiCatalogModule } from './api-catalog/api-catalog.module';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ApiCatalogModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
