import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * WG Imports
 */
import { LoaderModule, LoaderService } from '@watchguard/wg-loader';
import { WgSearchModule } from '@watchguard/wg-search';
import { WgExpanderModule } from '@watchguard/wg-expander';
import { WgDropdownModule } from '@watchguard/wg-dropdown';
import { WgNotificationsModule, WgNotificationsService } from '@watchguard/wg-notifications';
import { SharedModule } from '../shared/shared.module';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { SubCategoryListComponent } from './sub-category-list/sub-category-list.component';
import { ApiDetailsComponent } from './api-details/api-details.component';
import { PageNotFoundModule } from '../page-not-found/page-not-found.module';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';

const appRoutes: Routes = [
  {
      path: 'catalogs',
      component: CatalogsComponent
  },
  { path: 'catalogs/:id', component: SubCategoryListComponent },
  { path: 'catalogs/:id/:subcatid', component: SubCategoryListComponent },
  { path: 'catalogs/:id/:subcatid/:api/:version', component: ApiDetailsComponent },
  { path: '', redirectTo: 'catalogs', pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'locale-bundle/main/', '.json');
}

@NgModule({
  declarations: [
    CatalogsComponent,
    SubCategoryListComponent,
    ApiDetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PageNotFoundModule,
    SharedModule,
    WgSearchModule,
    LoaderModule,
    WgExpanderModule,
    WgDropdownModule,
    WgNotificationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(appRoutes, {})
  ],
  providers: [TranslateService, WgNotificationsService, LoaderService],
  exports: [
    CatalogsComponent,
    ApiDetailsComponent,
    TranslateModule,
    RouterModule
  ]
})
export class ApiCatalogModule { }
