<ng-container>
  <div>
    <a
    (click)="onPreviousPage()"
    style="cursor: pointer;">
    <wg-icon name="angle-left"></wg-icon>
    Back
   </a>
  </div>
  <div id='swagger-ui'></div>
</ng-container>
