import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import SwaggerUI from 'swagger-ui';

/**
 * WG imports
 */
import { CatalogApiService } from 'src/app/core/shared-services/catalog-api.service';

@Component({
  selector: 'wgc-api-details',
  templateUrl: './api-details.component.html'
})
export class ApiDetailsComponent implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private catalogService: CatalogApiService,
    private location: Location) {
  }

  ngAfterViewInit() {
    const DisableTryItOutPlugin = () => {
      return {
        statePlugins: {
          spec: {
            wrapSelectors: {
              allowTryItOutFor: () => () => false
            }
          }
        }
      };
    };
    const id = 'id';
    const subcatid = 'subcatid';
    const api = 'api';
    const version = 'version';
    SwaggerUI({
      url: this.catalogService.getApiSpecUrl(
        this.route.snapshot.params[id],
        this.route.snapshot.params[subcatid],
        this.route.snapshot.params[api],
        this.route.snapshot.params[version]
        ),
      domNode: this.el.nativeElement.querySelector('#swagger-ui'),
      deepLinking: true,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        DisableTryItOutPlugin
      ]
    });
  }
  onPreviousPage() {
    this.location.back();
  }
}
