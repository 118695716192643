/**
 * @license
 * Copyright WatchGuard Technologies Inc. All Rights Reserved.
 *
 * This module exports HeaderService class
 *
 * This service is responsible for getting account related info from backend.
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class Api {
  constructor(public name: string, public title: string, public version: string, public description: string) {  }
}

@Injectable({
  providedIn: 'root'
})
export class CatalogApiService {

  private catalogData = '/catalog-data/';
  private catalogUrl = 'category-master-list.json';
  private apiListFileUrl: string;
  private apiSpecUrl: string;

  constructor(private http: HttpClient) { }

  getCatalogs(): Observable<any> {
    return this.http.get(this.catalogData + this.catalogUrl);
  }

  getApis(categoryId: string, subCategoryId: string): Observable<any> {
    this.apiListFileUrl = `${this.catalogData}${categoryId}/${subCategoryId}/${subCategoryId}.json`;
    return this.http.get(this.apiListFileUrl);
  }

  getApiSpecUrl(apiCat: string, apiSubCat: string, apiName: string, apiVersion: string): string {
    this.apiSpecUrl = `${this.catalogData}${apiCat}/${apiSubCat}/${apiName}/${apiVersion}/${apiName}-${apiVersion}.yaml`;
    return this.apiSpecUrl;
  }

  getApiList(data: object) {
    let apiList: Api[] = [];
    let title = '';
    Object.keys(data).forEach((key: string) => {
      let version: string[] = [];
      let description: string[] = [];
      let value = data[key];
      Object.keys(value).forEach((newKey: string) => {
        if (newKey === 'title') {
          title = value[newKey];
        } else if (value[newKey] instanceof Object) {
          version.push(newKey);
          description.push(value[newKey].description);
        }
    });
      for (let i = 0; i < version.length; i++) {
        apiList.push(new Api(key, title, version[i], description[i]));
      }
  });
    return apiList;
  }
}
