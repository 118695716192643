<ng-container>
  <h2 class="section-title">
    <a
      [routerLink]="['/catalogs']"
      style="cursor: pointer;">
      <wg-icon name="angle-left"></wg-icon>
    </a>
      {{categoryName}} Category
  </h2>
  <div class="table-header-row">
    <div></div>
    <wg-search
      [placeholder]="'Search'"
      [initObjects]="subCategoriesInitObject"
      [filterKeys]="filterKeys"
      [searchTerm]="searchTerm"
      (filterObjects)="filteredSubCategories($event)"></wg-search>
  </div>
  <br>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-6">
        <div class="dropdown-container">
          <h4>Sub-category</h4>
          <wg-dropdown
            [userSelection]="subCategorySelected"
            [dropdownList]="subCategoryNames"
            (selectedEvent)="onSubCategorySelected($event)">
          </wg-dropdown>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <wg-expander *ngFor="let subCategory of subCategories; index as i" [expanded]="true" >
      <div class="header">
        <h3>
          <wg-icon
            class="size-auto"
            name="{{subCategory.ThumbnailIcon}}">
          </wg-icon> {{subCategory.name}}</h3>
      </div>
      <div class="content row">
        <div *ngFor="let api of apis[i]"
          class="col-sm-3"
          [routerLink]="['/catalogs',categoryId,subCategory.id,api.name,api.version]">
          <div class="card" style="cursor: pointer">
            <div class="card-body">
              <h5 class="card-title">{{api.title}} [{{api.version}}]</h5>
              <p class="card-text">{{api.description}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content row" style="margin-top: 10px">
        <small class="text-muted"><a href={{subCategory.HelpOrReferenceUrl}} target="_blank">Read more...</a></small>
      </div>
    </wg-expander>
  </div>
<wg-notifications></wg-notifications>
<wg-loader
  *ngIf="fetchingData"
  [scale]="2"
  [modal]="true"
  [color]="'#666666'">
</wg-loader>
</ng-container>
